<template>
  <div class="phonelist-wrap">
    <div class="bread-wrap">
      <span class="bread-title">通用云手机列表</span>
    </div>

    <div class="content">
      <div class="list-top">
        <el-button
          v-if="!subAccount"
          type="primary"
          class="createphone"
          @click="navtocreate"
          >创建通用云手机</el-button
        >
        <el-button type="primary" class="uploadphone" @click="upload"
          >上传文件至云手机</el-button
        >
        <el-button
          v-if="!subAccount"
          type="primary"
          class="batchRenew"
          @click="batchrenew"
          >批量续费</el-button
        >
        <el-button
          v-if="!subAccount"
          type="primary"
          class="batchChangeGroup"
          @click="batchchangegroup"
          >批量修改分组</el-button
        >
        <!-- <span v-if="!subAccount" class="select-title">用户名</span> -->
        <!-- <el-dropdown
          v-if="!subAccount"
          class="select-type"
          @command="selectSubAccount"
        >
          <el-button plain type="primary">
            {{ currentAccount
            }}<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              :key="index"
              v-for="(item, index) in subAccounts"
              v-text="item.Username"
              :command="item.UserId"
            ></el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown> -->
        <el-button
          size="mini"
          type="primary"
          class="refreshList"
          @click="handleSearch"
          >刷新</el-button
        >
        <el-button
          size="mini"
          type="primary"
          class="refreshList2"
          @click="settingClick"
          >设置</el-button
        >
        <el-button
          class="filter-item"
          size="mini"
          type="primary"
          @click="handleSearch"
        >
          搜索
        </el-button>
        <el-input
          class="search-input"
          v-model.trim="query"
          size="mini"
          placeholder="请输入搜索文本（支持模糊搜索）"
          style="width: 220px"
          @keyup.enter.native="handleSearch"
          clearable
        />
      </div>
      <div class="screen" v-if="isSearch">
        <div class="screen-title">
          共{{ this.tabledata.length }}条数据<el-divider
            direction="vertical"
          ></el-divider
          >筛选条件：{{ this.query }}
        </div>
        <el-button size="mini" type="primary" class="clear" @click="refreshlist"
          >清空</el-button
        >
      </div>
      <com-table
        v-loading="loading"
        ref="comtable"
        v-if="showcolumn"
        class="phonelist"
        :headercellstyle="headercellstyle"
        :columns="showTableColumn"
        :data="tabledata"
        :rowkey="(tabledata) => tabledata.Id"
        tableheight="calc(100vh - 300px)"
        :isselectcount="true"
        :selectcount="selection.length"
        :pagination="pagination"
        @handleSelectionChange="handleSelectionChange"
        @cellmouseenter="cellmouseenter"
        @cellmouseleave="cellmouseleave"
        @sortChange="sortChange"
      >
        <el-table-column
          align="center"
          header-align="center"
          slot="action"
          label="操作"
          min-width="260"
        >
          <template slot-scope="scope">
            <el-button
              class="modifybtn"
              type="primary"
              size="mini"
              @click="login(scope.row)"
              >登录</el-button
            >
            <el-button
              class="modifybtn"
              type="primary"
              size="mini"
              @click="singleupload(scope.row)"
              >上传</el-button
            >
            <el-dropdown v-if="!subAccount" class="more-dropdown">
              <el-button
                icon="el-icon-more"
                class="morebtn"
                size="mini"
                type="primary"
              >
              </el-button>
              <template>
                <el-dropdown-menu>
                  <el-dropdown-item
                    @click.native="showAutoRenewModel(scope.row)"
                    >自动续费管理</el-dropdown-item
                  >
                  <el-dropdown-item
                    v-if="scope.row.ProductId == 'phone-noip'"
                    @click.native="showsetipmodel(scope.row)"
                    >配置IP</el-dropdown-item
                  >
                  <el-dropdown-item @click.native="gorenew(scope.row)"
                    >续费</el-dropdown-item
                  >
                </el-dropdown-menu>
              </template>
            </el-dropdown>
            <el-button
              class="modifybtn"
              v-else-if="scope.row.ProductId == 'phone-noip'"
              @click.native="showsetipmodel(scope.row)"
              type="primary"
              size="mini"
            >
              配置IP
            </el-button>
          </template>
        </el-table-column>
      </com-table>
    </div>
    <delete-item
      :title="title"
      canceltext="取消"
      confirmtext="确认"
      :showdelete="showdelete"
      @closedelete="closedelete"
      :row="row"
    />
    <edite-group
      title="修改分组"
      :row="editrow"
      :showeditegroup="showeditegroup"
      @closeeditegroup="closeeditegroup"
    />
    <edite-name
      title="修改名称"
      :row="row"
      :showeditename="showeditename"
      @closeeditename="closeeditename"
    />
    <single-up-load
      title="上传至云手机"
      :needfooter="false"
      :phoneid="this.row.Id"
      :phonename="this.row.Name"
      :row="uploadrow"
      :showsingleuploadmodel="showsingleuploadmodel"
      @closeshowsingleupload="closeshowsingleupload"
    />
    <!-- <phone-list-control-model
      @closemodelcontrol="closemodelcontrol"
      :showmodel="showmodel"
      :data="modeldata"
      :iftongbu="iftongbu"
      @singleuploadmodel="singleuploadmodel"
    /> -->
    <renew-order :row="row" :drawer="drawer" @closedrawer="closedrawer" />
    <auto-renew
      title="自动续费管理"
      :row="row"
      :showAutoRenew="showAutoRenew"
      @closeAutoRenew="closeAutoRenew"
    />
    <control-model
      @closemodelcontrol="closemodelcontrol"
      :showmodel="showmodel"
      :data="modeldata"
      :modeldata="modeldata"
      :iftongbu="iftongbu"
      @singleuploadmodel="singleuploadmodel"
    />
    <renew-order :row="row" :drawer="drawer" @closedrawer="closedrawer" />
    <auto-renew
      title="自动续费管理"
      :row="row"
      :showAutoRenew="showAutoRenew"
      @closeAutoRenew="closeAutoRenew"
    />
    <setting-list
      title="自定义列表"
      :data-arr="phoneAllList"
      :check-list="checkProp"
      :default-arr="DefaultPropertyArrForManage"
      :showsettinglist="showsettinglist"
      @closesettinglist="closesettinglist"
      @confirmedit="confirmedit"
    />
    <set-ip
      title="配置IP"
      :row="row"
      :needfooter="false"
      :showsetip="showsetip"
      @closesetipmodel="closesetipmodel"
    />
  </div>
</template>
<script>
import ComTable from "@/components/Table";
import RenewOrder from "./RenewOrder/index";
import DeleteItem from "./Models/DeleteItem";
import EditeGroup from "./Models/EditeGroup";
import EditeName from "./Models/EditeName";
import SettingList from "./Models/SettingList";
import { phoneList, phoneAllList } from "@/utils/tableList";
import navigation from "@/utils/navigation";
import { mapGetters } from "vuex";
import SingleUpLoad from "../PhoneControl/Models/SingleUpLoad.vue";
import ControlModel from "../PhoneControl/ControlModel/index.vue";
import PhoneListControlModel from "../PhoneControl/PhoneListControlModel/index.vue";
import { groupconnection } from "@/utils/groupconnection";
import AutoRenew from "./Models/AutoRenew";
import SetIp from "./Models/SetIp.vue";
import { phonestatus, productions } from "@/utils/optionList";

export default {
  name: "phonelist",
  data() {
    return {
      isSearch: false,
      query: "",
      headercellstyle: {
        background: "rgba(228, 229, 241)",
        color: "rgba(102,102,102)",
      },
      tabledata: [],
      tabledatabak: [],
      newtabledata: [],
      phoneList,
      phoneAllList,
      showTableColumn: phoneList,
      pagination: {
        current: 1,
        size: 20,
        total: 1,
      },
      showdelete: false,
      row: {},
      editrow: [],
      uploadrow: [],
      title: "删除云手机",
      drawer: false,
      showeditegroup: false,
      showeditename: false,
      showAutoRenew: false,
      showsettinglist: false,
      selection: [],
      selection2: [],
      phoneRegions: [],
      selectorigin: [],
      remindTime: 7 * 86400,
      needReminder: false,
      remindContent: "",
      remindTimeList: [],
      subAccounts: [],
      currentAccount: "全部",
      showcolumn: false,
      showsingleuploadmodel: false,
      showmodel: false,
      iftongbu: false,
      modeldata: {},
      checkProp: [], // 选中字段
      DefaultPropertyArrForManage: [
        "名称",
        "ID",
        "规格",
        "IP地域",
        "IP",
        "状态",
        "剩余时间",
        "业务组",
      ], // 默认选中字段
      showsetip: false,
      sortProp: "",
      sortOrderDesc: true,
      timer: null,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["subAccount"]),
  },
  watch: {
    tabledata: {
      handler: function (val, oldval) {
        if (val !== oldval) this.newtabledata = val;
      },
      deep: true,
    },
  },
  components: {
    ComTable,
    DeleteItem,
    RenewOrder,
    EditeGroup,
    EditeName,
    SingleUpLoad,
    PhoneListControlModel,
    ControlModel,
    AutoRenew,
    SettingList,
    SetIp,
  },
  methods: {
    getrefreshtoken() {
      this.$store.dispatch("user/userresettoken").then((res) => {
        console.log("userresettoken: " + JSON.stringify(res));
      });
    },
    showsetipmodel(row) {
      this.row = row;
      this.showsetip = !this.showsetip;
    },
    closesetipmodel() {
      this.showsetip = !this.showsetip;
    },
    navtocreate() {
      navigation.navigateTo({
        url: "createphone",
        params: {},
      });
    },
    refreshlist() {
      this.query = "";
      this.getphonelist();
    },
    showdrawer(row) {
      this.drawer = !this.drawer;
      this.row = row;
    },
    closedrawer() {
      this.drawer = !this.drawer;
    },
    closedelete() {
      this.showdelete = !this.showdelete;
    },
    showdeleteitem(row) {
      this.row = row;
      this.showdelete = !this.showdelete;
    },
    sortChange(prop, order) {
      console.log(prop, order);
      if (!order) {
        (this.sortOrderDesc = true), (this.sortProp = "");
      } else if (prop == "RemainTime") {
        this.sortProp = "ExpireTime";
        this.sortOrderDesc = order == "descending";
      }
    },
    sortMethod(data) {
      let sign = this.sortProp;
      if (sign != "ExpireTime") {
        sign = "CreateTime";
      }
      data = data.sort((a, b) => {
        if (this.sortOrderDesc) {
          return b[sign] - a[sign];
        } else {
          return a[sign] - b[sign];
        }
      });
      return data;
    },

    editegroup(row) {
      if (this.subAccount) {
        this.$message.warning(
          "子账号暂不支持修改分组功能，请登录主账号进行操作~"
        );
        return;
      }
      //this.row = row;
      this.editrow.push(row);
      this.showeditegroup = !this.showeditegroup;
    },
    closeeditegroup() {
      this.showeditegroup = !this.showeditegroup;
      // this.getphonelist();
      this.handleSearch();
      this.editrow = [];
      this.$refs.comtable.$refs.myTable.clearSelection();
    },
    editename(row) {
      if (this.subAccount) {
        this.$message.warning(
          "子账号暂不支持修改名称功能，请登录主账号进行操作~"
        );
        return;
      }
      this.row = row;
      this.showeditename = !this.showeditename;
    },
    closeeditename() {
      this.showeditename = !this.showeditename;
      // this.getphonelist();
      this.handleSearch();
    },
    showAutoRenewModel(row) {
      console.log(row);
      this.showAutoRenew = !this.showAutoRenew;
      this.row = row;
    },
    closeAutoRenew() {
      this.showAutoRenew = !this.showAutoRenew;
      // this.getphonelist();
      this.handleSearch();
      this.$refs.comtable.$refs.myTable.clearSelection();
    },
    upload() {
      if (Object.entries(this.selection).length == 0) {
        this.$message.warning("至少需选择一个项目");
        return;
      }
      this.uploadrow = this.selection;
      this.showsingleuploadmodel = !this.showsingleuploadmodel;
    },
    singleupload(row) {
      this.selectorigin = [row];
      this.selection2 = [];
      for (let item of [row]) {
        let renewform = {
          product_type: "Phone",
          region: item.IpRegion,
          config_type: item.ProductId,
          name: item.Id,
          group: item.GroupName,
          status: item.State,
        };
        this.selection2.push(renewform);
      }
      this.uploadrow = this.selection2;
      this.showsingleuploadmodel = !this.showsingleuploadmodel;
    },
    closeshowsingleupload() {
      this.showsingleuploadmodel = !this.showsingleuploadmodel;
      // this.getphonelist();
      this.handleSearch();
      this.selection2 = [];
    },
    singleuploadmodel() {
      this.selection2 = [];
      for (let item of [this.modeldata]) {
        let renewform = {
          product_type: "Phone",
          region: item.IpRegion,
          config_type: item.ProductId,
          name: item.Id,
          group: item.GroupName,
          status: item.State,
        };
        this.selection2.push(renewform);
      }
      this.uploadrow = this.selection2;
      this.showsingleuploadmodel = !this.showsingleuploadmodel;
    },

    login(row) {
      if (row.State == "DUE") {
        this.$message.warning("云手机已过期");
        return;
      } else if (row.State == "RESETTING") {
        this.$message.warning("设备重置中");
        return;
      } else if (row.State == "REBOOTING") {
        this.$message.warning("设备重启中");
        return;
      } else if (row.State == "CREATING") {
        this.$message.warning("设备创建中");
        return;
      } else if (row.State == "RENEWING") {
        this.$message.warning("更换机型中");
        return;
      } else if (row.State == "CREATE_FAILED") {
        this.$message.warning("设备创建失败");
        return;
      } else if (row.State == "SETTING_ROOT") {
        this.$message.warning("设置Boot中");
        return;
      }
      console.log(row);
      this.modeldata = {
        phoneId: row.Id,
        ...row,
      };
      this.showmodel = !this.showmodel;
    },
    closemodelcontrol() {
      this.showmodel = !this.showmodel;
    },
    batchrenew() {
      if (Object.entries(this.selection).length == 0) {
        this.$message.warning("至少需选择一个项目");
        return;
      }
      navigation.navigateTo({
        url: "renewconfirm",
        params: {
          payload: {
            ...this.selection,
          },
        },
      });
    },
    gorenew(item) {
      let renewform = [
        {
          product_type: "Phone",
          region: item.IpRegion,
          config_type: item.ProductId,
          name: item.Id,
          group: item.GroupName,
          status: item.State,
        },
      ];
      navigation.navigateTo({
        url: "renewconfirm",
        params: {
          payload: {
            ...renewform,
          },
        },
      });
    },
    batchchangegroup() {
      if (Object.entries(this.selectorigin).length == 0) {
        this.$message.warning("至少需选择一个项目");
        return;
      }
      this.editrow = this.selectorigin;
      this.showeditegroup = !this.showeditegroup;
    },
    handleSelectionChange(val) {
      console.log("handleSelectionChange #1: " + JSON.stringify(val));
      this.selectorigin = val;
      this.selection = [];
      for (let item of val) {
        let renewform = {
          product_type: "Phone",
          region: item.IpRegion,
          config_type: item.ProductId,
          name: item.Id,
          group: item.GroupName,
          status: item.State,
        };
        this.selection.push(renewform);
      }
      //console.log("handleSelectionChange renewform all: " + JSON.stringify(this.selection));
    },
    //获取IP地域
    getPhoneRegions() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch("pay/getipregionlist").then((res) => {
          if (res.RetCode == 0) {
            resolve(res.IpRegions);
            this.phoneRegions = res.IpRegions;
          }
        });
      });
    },
    //获取子账号列表
    getsubaccountlist() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch("sub/getsublist").then((res) => {
          if (res.RetCode == 0) {
            if (this.$store.getters.subAccount) {
              var list = phoneList.filter((item) => {
                if (item.prop == "SubAccount") {
                  return false;
                }
                return true;
              });
              this.phoneList = list;
            } else {
              phoneList.forEach((item) => {
                if (item.prop == "SubAccount") {
                  item.filters = this.$store.getters.filterlist;
                }
              });
              this.phoneList = phoneList;
            }
            this.showcolumn = true;
            resolve(res.UserInfos);
          }
        });
      });
    },
    //获取云手机列表
    gettablelist() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch("group/getphonelist").then((res) => {
          if (res.RetCode == 0) {
            resolve(res.PhoneInfos);
          }
        });
      });
    },
    getphonelist() {

        let data = {
          OrderId: "1608781116771143680",
        };
        this.$store.dispatch("group/getgetphoneidsfororderid", { ...data }).then((res) => {
          if (res.RetCode == 0) {
            

          }
        });
        
      this.loading = true;
      Promise.all([
        this.gettablelist(),
        this.getsubaccountlist(),
        this.getPhoneRegions(),
      ]).then(([PhoneInfos, UserInfos, IpRegions]) => {
        this.loading = false;
        PhoneInfos.forEach((pitem, pindex) => {
          // IpRegions.forEach((ritem, rindex) => {
          //   if (pitem.IpRegion == ritem.RegionId) {
          //     pitem.IpRegionName = ritem.Name;
          //   }
          // });
          if (pitem.ProductId == "phone-noip" && pitem.IpConfig !== null) {
            pitem.IP = pitem.IpConfig.Socks.Uri;
          }
          UserInfos.forEach((uitem, uindex) => {
            if (pitem.UserId == uitem.UserId) {
              pitem.SubAccount = uitem.Username;
            }
          });
        });
        this.tabledata = this.sortMethod(PhoneInfos);
        this.tabledata.sort(function (a, b) {
          return a.Name > b.Name ? 1 : -1;
        });
        this.remindTimeList = [];
        this.tabledata.forEach((item) => {
          //计算剩余时间
          var timestampNow = new Date().getTime() / 1000; //second
          var remainTime = item.ExpireTime - timestampNow;
          item.RemainTimeStamp = remainTime;
          var remainText = this.secondToDate(remainTime);
          if (remainTime < 0) {
            item.RemainTime = "--------";
          } else {
            item.RemainTime = remainText;
          }
          this.remindTimeList.push({ id: item.Id, time: remainTime });
        });
        this.tabledatabak = this.tabledata; //保存一份，后面筛选使用，避免频繁请求后台

        if (this.needReminder) {
          this.needReminder = false;
          this.checkPhoneIndate();
        }
      });
      this.isSearch = false;
    },
    handleSearch() {
      if (this.query != null && this.query.length > 0) {
        this.searchphonelist();
      } else {
        this.getphonelist();
      }
    },
    settingClick() {
      this.showsettinglist = !this.showsettinglist;
      if (this.showsettinglist) {
        this.checkProp = this.DefaultPropertyArrForManage;
        console.log("gggggg1", this.checkProp);
      }
    },
    closesettinglist() {
      this.showsettinglist = !this.showsettinglist;
    },
    confirmedit(val) {
      this.checkProp = val;
      this.dealTableColumn(this.checkProp);
    },
    // 重新渲染table表格
    dealTableColumn(val) {
      this.showTableColumn = [];
      this.DefaultPropertyArrForManage = [
        "名称",
        "ID",
        "规格",
        "IP地域",
        "IP",
        "状态",
        "剩余时间",
        "业务组",
      ];
      this.showTableColumn.push(this.phoneList[0]);
      for (let i = 0; i < this.phoneAllList.length; i++) {
        for (let j = 0; j < val.length; j++) {
          if (this.phoneAllList[i].label == val[j]) {
            this.showTableColumn.push(this.phoneAllList[i]);
            if (
              !this.DefaultPropertyArrForManage.includes(
                this.phoneAllList[i].label
              )
            ) {
              this.DefaultPropertyArrForManage.push(this.phoneAllList[i].label);
            }
          }
        }
      }
    },
    getProduction(val) {
      try {
        productions.forEach((item, index) => {
          var name = "";
          if (item.ProductId == val) {
            name = item.Name;
            throw name;
          }
        });
      } catch (name) {
        if (name) {
          return name;
        }
      }
    },
    getsearchregion(val) {
      let region = val;
      try {
        this.phoneRegions.forEach((item, index) => {
          if (item.RegionId == val) {
            throw item.Name;
          }
        });
      } catch (name) {
        region = name;
      }
      return region;
    },
    getphonestatus(val) {
      let status;
      let statusindex;
      if (
        phonestatus.some((item, index) => {
          statusindex = index;
          return item.status == val;
        })
      ) {
        status = phonestatus[statusindex].statusname;
      } else {
        status = val;
      }
      return status;
    },

    searchphonelist() {
      var list = this.tabledata.filter((item) => {
        if (
          item.Name.includes(this.query) ||
          item.Id.includes(this.query) ||
          item.GroupName.includes(this.query) ||
          this.getProduction(item.ProductId).includes(this.query) ||
          this.getsearchregion(item.IpRegion).includes(this.query) ||
          this.getphonestatus(item.State).includes(this.query) ||
          item.IP.includes(this.query)
        ) {
          return true;
        }
      });
      this.tabledata = list;
      this.isSearch = true;
    },
    getlinelist() {
      this.$store.dispatch("line/getproductlist").then((res) => {
        console.log("get linelist response: " + JSON.stringify(res));
        if (res.lines !== null && res.lines.length > 0) {
          res.lines.forEach((item, index, arr) => {
            var timestampNow = new Date().getTime() / 1000; //second
            var remanentTime = item.expire_time - timestampNow;
            if (remanentTime < this.remindTime) {
              this.remindContent += item.line_id;
              this.remindContent += ", ";
            }
          });
        }
      });
    },
    checkPhoneIndate() {
      if (this.remindTimeList != undefined && this.remindTimeList.length > 0) {
        // this.tabledata.forEach ( item => {
        //   var timestampNow = new Date().getTime()/1000; //second
        //   var remanentTime = item.ExpireTime - timestampNow;
        //   if (remanentTime < this.remindTime) {
        //     this.remindContent += item.Id;
        //     this.remindContent += ",";
        //   }
        // })
        this.remindTimeList.forEach((item) => {
          if (item.time < this.remindTime) {
            this.remindContent += item.id;
            this.remindContent += ",";
          }
        });
        if (this.remindContent != "") {
          this.remindContent +=
            " 即将到期，请注意及时续费，避免云手机被删除引起数据丢失！";
          //console.log("remind message: " + this.remindContent);
          var text =
            '<span style="font-style: normal;color: #5897F2;" id="messageTrack">' +
            this.remindContent +
            "</span>";
          this.$notify({
            title: "即将到期提醒",
            dangerouslyUseHTMLString: true,
            message: text,
            position: "bottom-right",
            duration: 5000,
          });
        }
      }
    },
    secondToDate: function (msd) {
      var time = Math.abs(msd);
      if (null != time && "" != time) {
        if (time > 60 && time < 60 * 60) {
          //1小时以内
          time =
            parseInt(time / 60.0) +
            "分钟" +
            parseInt((parseFloat(time / 60.0) - parseInt(time / 60.0)) * 60) +
            "秒";
        } else if (time >= 60 * 60 && time < 60 * 60 * 24) {
          //1天以内
          time =
            parseInt(time / 3600.0) +
            "小时" +
            parseInt(
              (parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60
            ) +
            "分钟";
          // + parseInt((parseFloat((parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60) -
          // parseInt((parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60)) * 60) + "秒";
        } else if (time >= 60 * 60 * 24) {
          //超过一天
          time =
            parseInt(time / 3600.0 / 24) +
            "天" +
            parseInt(
              (parseFloat(time / 3600.0 / 24) - parseInt(time / 3600.0 / 24)) *
                24
            ) +
            "小时";
          // + parseInt((parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60) + "分钟" +
          // parseInt((parseFloat((parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60) -
          // parseInt((parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60)) * 60) + "秒";
        } else if (time < 60) {
          time = parseInt(time) + "秒";
        }
      }
      if (msd >= 0) {
        return time;
      } else {
        return `-${time}`;
      }
    },

    selectSubAccount(command) {
      const findUser = this.subAccounts.find((item) => item.UserId == command);
      this.currentAccount = findUser.Username;
      if (this.tabledatabak.length != 0) {
        if (command == "all") {
          this.tabledata = this.tabledatabak;
        } else {
          this.tabledata = this.tabledatabak.filter(
            (item) => item.UserId == command
          );
        }
      }
    },
    cellmouseenter(row) {
      row.isShowBtn = true;
      let Arr = JSON.parse(JSON.stringify(this.tabledata));
      this.tabledata = JSON.parse(JSON.stringify(Arr));
    },
    cellmouseleave(row) {
      this.tabledata = this.tabledata.map((n) => {
        n.isShowBtn = false;
        return n;
      });
      let Arr = JSON.parse(JSON.stringify(this.tabledata));
      this.tabledata = JSON.parse(JSON.stringify(Arr));
    },
     getuserinfo() {
      this.$store.dispatch("user/getuserinfo").then((res) => {
        if (res.RetCode == 0) {
        }
      });
    },
  },
  mounted() {
    window.editename = this.editename;
    window.editegroup = this.editegroup;
  },

  activated() {
    this.getphonelist();
    this.getrefreshtoken();
    this.getuserinfo();
  },
  deactivated() {
    clearInterval(this.timer);
    this.$notify.close();
  },
  //跳转的路由name
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.name == "login" || to.query.reminder == "true") {
        vm.needReminder = true;
        //vm.getlinelist();
      }
    });
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>